import { AfterViewInit, Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subscription } from 'rxjs';
import { Editor } from 'tinymce';

export const TINYMCE_OVERLAY_TRIGGERING_CHAR = '/';

@Directive({
  selector: '[appTinyEditorOverlay]',
  standalone: true
})
export class TinyEditorOverlayDirective implements OnInit, AfterViewInit, OnDestroy {

  @Input({required: true, alias: 'appTinyEditorOverlay'}) overlay: OverlayPanel | undefined;

  editor: Editor | undefined;
  subs: Subscription[] = [];
  fallbackIndex: number = 0;

  get appendTo() { return this.editor?.editorContainer }; // primeng neumi appendout na element ktery je v iframe/shadowdom, tak appendujem aspon na container
  APPEND_TO_ID = 'tiny-editor-overlay-append-to';

  constructor(
    private host: EditorComponent
  ) { }

  ngOnInit(): void {
    this.host.onInit.subscribe((e) => {
      this.editor = e.editor;
      this.registerEditorEvents();
    });
  }

  ngAfterViewInit(): void { }

  private registerEditorEvents(): void {
    this.editor?.on('keypress', (e: KeyboardEvent) => this.onEditorKeyPress(e));
  }

  private onEditorKeyPress(e: KeyboardEvent): void {
    if (e.key === TINYMCE_OVERLAY_TRIGGERING_CHAR) {

      this.editor?.insertContent(`<span id="${this.APPEND_TO_ID}"></span>`);

      this.showOverlay();
    } else {
      this.hideOverlay();
    }
  }

  private showOverlay(): void {
    this.overlay?.show(new Event('click'), this.appendTo);
  }

  public hideOverlay(): void {
    this.overlay?.hide();

    this.editor?.getBody().querySelectorAll(`#${this.APPEND_TO_ID}`).forEach((el, i) => {
      el.remove();
    });
  }

  public editorInsertText(text: string): void {
    if (!this.editor) return;
    
    const cursorPos = this.editor.selection.getRng().startOffset;

    // get character before cursor
    const charBeforeCursor = this.editor.selection.getRng().startContainer.textContent?.charAt(cursorPos - 1);
    if (charBeforeCursor === TINYMCE_OVERLAY_TRIGGERING_CHAR) {
      const selection = this.editor.selection.getRng();
      const container = selection.startContainer as Text;
      const start = selection.startOffset;
      container.deleteData(start - 1, 1);
    }

    this.editor?.execCommand('mceInsertContent', false, text);

    this.hideOverlay();
    // this.editor.selection.setCursorLocation(null, textBeforeCursor.length + text.length);
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
    if (this.editor) {
      this.editor.off('keypress', this.onEditorKeyPress);
    }
  }
}