



@if (FC) {
  <editor
    #editor
    [formControl]="FC"
    [init]="editorSettings"
    [appTinyEditorOverlay]="overlay"
    (onInit)="inited($event)">
  </editor>
}